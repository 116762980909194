/*global chrome*/
import React, { useState, useEffect } from "react";
import "./App.css";
import AccordionTransition from "./ProductPage";
import { IoLogoChrome } from "react-icons/io";
import { useParams } from "react-router-dom";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  getAuth,
} from "firebase/auth";
import { app, auth } from "./firebase";
import GoogleSignInButton from "./components/GoogleSignInButton";
import AppleSignInButton from "./components/AppleSignInButton";
import { loginUser, getUserInfo, loginWithEmail } from "./redux/actions/auth";
import NoCredits from "./components/NoCredits";
import Login from "./components/Login";
import { IoArrowBackCircle } from "react-icons/io5";
import AsinComponent from "./components/SettingComponents/AsinComponent";
import { useNavigate } from "react-router-dom";
import { fetchSettings, updateSettings } from "./redux/actions/productActions";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "sonner";

function Main({ userData }) {
  const navigate = useNavigate();

  const [user, setUser] = useState(userData);
  const [showRedirect, setShowRedirect] = useState(false);
  const [settingsData, setSettingsData] = useState(null);
  let asinFromParams = useParams()?.asin;
  let marketplace = useParams()?.marketplace?.toLowerCase().replace("www.", "");
  const hasAccessToken = localStorage.getItem("accessToken");
  const [loginLoading, setLoginLoading] = useState(false); // email, google, apple, false => states of loginLoading

  if (asinFromParams === "undefined") {
    asinFromParams = undefined;
  }
  const [asin, setAsin] = useState(asinFromParams);
  const [productId, setProductId] = useState("");

  useEffect(() => {
    if (userData) {
      const getSettings = async () => {
        const settingsData = await fetchSettings();
        setSettingsData(settingsData);
        if (settingsData?.marketplace !== marketplace) {
          const updatedSettings = {
            ...settingsData,
            marketplace: marketplace,
          };
          const data = await updateSettings(updatedSettings);
          setSettingsData(data);
        }
      };

      getSettings();
    }
  }, [userData]);

  const providerApple = new OAuthProvider("apple.com");
  providerApple.addScope("email");
  providerApple.addScope("name");

  const providerGoogle = new GoogleAuthProvider();

  const login = async (uid) => {
    try {
      const data = await loginUser(uid);
      if (data) {
        setUser(data);
      } else {
        setShowRedirect(true);
      }
      setLoginLoading(false);
    } catch (error) {
      console.log("error >>>> ", error);
      setLoginLoading(false);
    }
  };

  // const auth = getAuth(app)
  // auth.languageCode = 'it';

  const SIGN_IN_APPLE = () => {
    setLoginLoading("apple");
    signInWithPopup(auth, providerApple)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        // Apple credential
        // const credential = OAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        // const idToken = credential.idToken;

        // IdP data available using getAdditionalUserInfo(result)
        // ...

        login(user?.uid);
        setLoginLoading(false);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        setLoginLoading(false);
        console.log("errorCode >>>> ", errorCode);
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The credential that was used.
        // const credential = OAuthProvider.credentialFromError(error);

        // ...
      });
  };

  const SIGN_IN_GOOGLE = () => {
    setLoginLoading("google");
    signInWithPopup(auth, providerGoogle)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...
        login(user?.uid);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        setLoginLoading(false);
        console.log("errorCode >>>> ", errorCode);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const asin = productId;
    setAsin(asin);
  };

  const handleLogin = async (email, password) => {
    try {
      setLoginLoading("email");
      const user = await signInWithEmailAndPassword(auth, email, password);
      if (user) {
        const userId = user?.user?.uid;
        const data = await loginUser(userId);
        setUser(data);
        setLoginLoading(false);
      } else {
        setLoginLoading(false);
        setShowRedirect(true);
      }
    } catch (error) {
      console.log("error >>>> ", error);
      setLoginLoading(false);
      toast.error("Wrong Login Credentials!!");
    }
  };

  const backClickHandler = () => {
    setAsin(undefined);
    navigate("/");
  };

  return (
    <div className="App">
      <header
        className="w-full bg-primary-dark text-primary-light py-4 flex items-center justify-between"
        style={{
          zIndex: 1000,
        }}
      >
        <div className="flex items-center">
          {user && user?.credit_balance > 0 && asin !== undefined && (
            <IoArrowBackCircle
              onClick={backClickHandler}
              className="w-8 h-8 text-primary-light bg-primary-dark cursor-pointer"
            />
          )}
          <div className="flex items-center ml-2">
            {/* <IoLogoChrome className="text-2xl mr-2 w-8 h-8" /> */}
            <img
              src="../scanlogo.png"
              alt="logo"
              className="text-2xl w-6 h-6 mr-2"
            />
            <h1 className="text-xl font-bold">Scan Profit</h1>
          </div>
        </div>
      </header>
      <div className="">
        {!user || !hasAccessToken ? (
          // || (user?.subscriptionActive === false)
          <>
            <div className="mt-8 px-8 flex flex-col justify-center">
              <Login loginHandler={handleLogin} loginLoading={loginLoading} />
              {/* <GoogleSignInButton
                onClickHandler={SIGN_IN_GOOGLE}
                loginLoading={loginLoading}
              />
              <AppleSignInButton
                onClickHandler={SIGN_IN_APPLE}
                loginLoading={loginLoading}
              /> */}
              <div className="flex items-center gap-x-2 text-white">
                Don't have an account?{" "}
                <a
                  href="https://app.scanprofit.com/signup"
                  target="_blank"
                  className="text-primary-light transition-all ease-in-out duration-100 hover:text-primary-light/80"
                >
                  Create Account
                </a>
              </div>
            </div>

            {showRedirect && (
              // || user?.subscriptionActive === false
              <div className="mt-4 text-center">
                <p className="text-primary-light">
                  {" "}
                  You are not authorized to Login. Please Create an Account
                  First.
                </p>
                <button
                  className="bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300"
                  onClick={() => {
                    window.open("https://app.scanprofit.com");
                  }}
                >
                  Create Account
                </button>
              </div>
            )}
          </>
        ) : user?.credit_balance <= 0 || !user?.subscriptionActive ? (
          <NoCredits />
        ) : asin !== undefined ? (
          <>
            <AccordionTransition asin={asin} settingsData={settingsData} />
          </>
        ) : (
          <>
            <AsinComponent setAsin={setAsin} settingsData={settingsData} />
          </>
        )}
      </div>
    </div>
  );
}

export default Main;
