/*global chrome*/
import React, { useState, useEffect } from "react";
import "./App.css";
import Main from "./Main";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { getUserInfo } from "./redux/actions/auth";
import Settings from "./Settings";
import { Toaster } from "sonner";
import { fetchSettings } from "./redux/actions/productActions";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const hasAccessToken = localStorage.getItem("accessToken");
  // const [settingsData, setSettingsData] = useState(null);
  const [urlType, setUrlType] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      getUserInfo()
        .then((data) => {
          setUser(data);
        })
        .catch((error) => {
          console.log("error in useEffect >>>> ", error);
        })
        .finally(() => {
          setLoading(false);
        });

      // const getSettings = async () => {
      //   const settingsData = await fetchSettings();
      //   setSettingsData(settingsData);
      // }

      // getSettings();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    function handleMessage(event) {
      // Verify sender origin
      // if (event.origin !== 'https://www.amazon.com') return;

      if (event.data.type === "CLEAR_STORAGE") {
        console.log("Clearing data of react app...");
        // Clear localStorage
        localStorage.clear();
        // Clear cookies
        const cookies = document.cookie.split(";");
        for (let cookie of cookies) {
          const cookieName = cookie.split("=")[0].trim();
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
          // Clear cookie with current domain
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname}`;

          // Clear cookie with .current domain
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${window.location.hostname}`;
        }
      }
    }

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const router = createBrowserRouter([
    {
      path: ":asin?/:marketplace?",
      element: <Main userData={user} />,
    },
    {
      path: "/settings",
      element: hasAccessToken ? <Settings /> : <Navigate to="/" />,
    },
    {
      path: "/",
      element: <Main userData={user} />,
    },
  ]);

  if (loading) {
    return (
      <div className="text-center text-primary-light mt-6">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Toaster position="top-right" richColors closeButton />
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default App;
