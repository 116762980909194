import React from "react";

const NoCredits = () => {
  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-3 mt-8"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="mt-2 px-4 flex flex-col justify-center">
        <p className="text-primary-light">
          You don't have enough credits to access the application or your
          current plan is expired.
        </p>
        <p className="text-primary-light">
          Please buy credits to access the application.
        </p>
        <a
          className="bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300"
          href="https://app.scanprofit.com/dashboard"
          target="_blank"
        >
          Buy Credits
        </a>
      </div>
    </div>
  );
};

export default NoCredits;
